import React from "react"
import { KVList } from "./kvlist"


export const ProjectsListPreview = (props) => {

  const { project } = props;
  const { title, year, customer, teaser, path, teaserdescription, project_type_full,used_tech_full,my_tasks_full } = project;
  const imgsrc = teaser?.localFile?.childImageSharp?.fixed?.src;

  let ptf = project_type_full.map(p => p.name);
  let utf = used_tech_full.map(u => u.name);
  let mtf = my_tasks_full.map(m => m.name);

  const listValues = [
    //{label:"Jahr",value:year},
    //{label:"Projekt",value:title},
    {label:"Kunde",value:customer},
    {label:"Projektart",value:ptf},
    {label:"Tech",value:utf},
    {label:"Meine Aufgabe",value:mtf},

  ];

  return (
    <div className="project_preview">

      <div className="preview_image" style={{backgroundImage:`url(${imgsrc})`}}/>
      <div className="preview_data">
        <h2>{title}</h2>
        <div className="teaserdescription">{teaserdescription}</div>
        <div className="flex">
          <KVList className={"preview_data_list"} list={listValues}/>
        </div>

      </div>

      <a href={path} className={"project_preview_overlay"}/>
    </div>
  )
}
