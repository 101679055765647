import React from "react"
import BaseLayout from "../layout/BaseLayout"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import BlogList from "../components/blogList"
import ProjectsList from "../components/projectslist"
import DoubleHeader from "../blocks/doubleHeader"
import {Card,Cards} from "../blocks/cards"
import {SmallButton} from "../blocks/buttons"

const PageMdx = (data) => {
  const { pageContext } = data;

  const components = {BlogList,ProjectsList,DoubleHeader,Cards,Card,SmallButton}

  return (
    <BaseLayout context={pageContext}>
      <MDXProvider components={components}>

          <MDXRenderer>{pageContext.body}</MDXRenderer>

      </MDXProvider>
    </BaseLayout>
  )
}

export default PageMdx
