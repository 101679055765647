import React from "react"

const DoubleHeader = (props) => {
  const {title,children} = props;

  return (
    <div className="double_header">
      <h2 className="smallH2">{title}</h2>
      <div className="large_content">
        {children}
      </div>
    </div>
  )

}


export default DoubleHeader;
