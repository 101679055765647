import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ProjectsListPreview } from "./projectslistpreview"


const ProjectsList = (props) => {

  const query = graphql`
  {
    allWordpressWpShowcase {
      edges {
        node {
          id
          title
          type
          year
          description
          description_rendered
          teaserdescription
          customer
          path
          project_type_full {
            name
            slug
          }
          my_tasks_full {
            name
            slug
          }
          used_tech_full {
            name
            slug
          }
          images_urls {
            url {
              id
              localFile {
                id
                childImageSharp {
                  fixed(width: 1200) {
                    src
                    width
                    height
                  }
                }
              }
              title
              type
            }
          }
          internal {
            content
          }
          teaser {
            id
            localFile {
              childImageSharp {
                fixed(width: 600) {
                  width
                  src
                }
              }
            }
          }
          template
        }
      }
    }
  }
`

  const data = useStaticQuery(query)
  let nodes = data.allWordpressWpShowcase?.edges?.map(e => e.node)


  return (
    <div className={"project_list"}>{
      nodes.map((n) => {
        return (
          <ProjectsListPreview key={n.id} project={n}/>
        )
      })
    }</div>
  )

}

export default ProjectsList
