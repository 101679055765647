import React from "react"
import {Link} from "gatsby";
import "./styles/buttonstyles.scss"


const Button = (props) => {

  const {children} = props;

  return (
    <div className={"cards"}>
      {children}
    </div>
  )

}

const SmallButton = (props) => {

  const {children,to,className} = props;

  const cn = `${className} smallbutton`

  return (
    <Link className={cn} to={to}>
      {children}
    </Link>
  )

}




export {Button,SmallButton}
