import React from "react"
import "./styles/cardstyles.scss"


const Cards = (props) => {

  const {children} = props;

  return (
    <div className={"cards"}>
      {children}
    </div>
  )

}

const Card = (props) => {

  const {children, className} = props;

  return (
    <div className={"card " + className}>
      {children}
    </div>
  )

}




export {Cards,Card}
