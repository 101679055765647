import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"

const BlogListItem = (props) => {
  const { path, date, title,teaser_text } = props

  return (
    <div className={"bloglistitem"}>
      <Link to={path}>
        <span className={"date h3"}>{date}</span>
        <h2>{title}</h2>
        <div>{teaser_text}</div>
      </Link>
    </div>
  )
}


const BlogList = () => {

  const data = useStaticQuery(graphql`
   {
    
      allWordpressPost {
        edges {
          node {
            _fx_post_type
            date(formatString: "DD.MM.YYYY")
            teaser_text
            title
            type
            slug
            link
            tags
            status
          }
        }
      }
  }
  `)

  const posts = data?.allWordpressPost?.edges.filter(({node}) => {
    return node._fx_post_type !== "dontshow";
  });
  console.log(posts);

  return (
    <>
      <div className={"bloglist"}>
        {
         posts.map((p) => {
           const {node} = p;

           const path = "post/" + node.slug;
           return (

              <BlogListItem key={node.slug} title={node.title} path={path} date={node.date} teaser_text={node.teaser_text}/>

            )
          })
        }
      </div>
    </>
  )
}

export default BlogList
